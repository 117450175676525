<template>
    <div
        v-loading="loading"
        element-loading-background="rgba(122, 122, 122, 0.1)"
        element-loading-text="Please wait"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        style="z-index: 1"
    >
      <vc-sockets :channel="`policy-channel`" listen="CarPolicyCountSocketEvent" @action="listing(invoiceP.page, false)" />
        <vc-sockets v-if="user_id" :channel="`channel-${user_id}`" listen="CarInvoiceSocketEvent" @action="listing(invoiceP.page, false)" />

        <div class="card card-policy-assigned">
            <div class="container container-lead bg-white border-radius-4">
                <vc-invoice-first :transaction-types="payments" @refresh="listing(invoiceP.page, false)" />
                <vc-invoice-second :task-counts="dueCounts" />
            </div>
        </div>
          <!-- <vc-modal-upload-receipt :invoice_id="invoice_id" ref="upload_receipt" @refresh="listing(invoiceP.page, false)" />
        <vc-modal-invoice-details :data="invoiceData" ref="invoice_details" />
        <vc-modal-invoice 
            :data="invoiceData" 
            ref="invoice_ref" 
            :message="selectedOpt.name" 
            :quote_ref_no="selectedRow.quote_ref_no" 
            :is-loading="loadingModal"
            :has-error="invoiceAlreadyActioned"
            :actioned-message="invoiceAlreadyActionedMessage"
            @cancel="cancelOpt"
            @submit="SubmitOpt"
            @contData="continueData"
        /> -->
        <vc-invoice-third :data="datas" :loading="loadOnce" :footer-data="footerData" @action="action" @refresh="listing(invoiceP.page, false)" @process="handleOption" />
         
    </div>
</template>

<script setup>
import { computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useListing, invoiceP, setInvoiceP } from '@/store/composable/Listing'
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useUser } from '@/store/composable/User'
import InvoiceService from "@/core/services/car/InvoiceService";

const store = useStore()
const loading = ref(false)
const loadOnce = ref(true)
const route = useRoute()
const datas = ref([])
const invoice_ref = ref()
const selectedRow = ref({})
const selectedOpt = ref({})
const loadingModal = ref(false)
 const { user_id, role_id } = useUser()

onMounted(() => {
    let type = 'all'
    setInvoiceP(type, role_id)
    // getPayments()
})

onBeforeUnmount(() => {
    let type = 'all'
    setInvoiceP(type, role_id)
})

watch(() => invoiceP.value, async(invoice) => {
    await listing(invoice.page)
}, { deep: true })

const counts = ref({})
async function count() {
    const params = {
        linkType: `${route.name}-count`,
        ...invoiceP.value,
        count: true
    }
    const response = await useUpdateCustomerData(params)
    counts.value = response.data.data.invoices
}

const total = ref(0)
const footerData = ref({
    from: 0,
    to: 0,
    total: 0
})
async function listing(e, forceLoad = true) {
    if(forceLoad) loading.value = true

    window.scrollTo({ top: 0, behavior: 'smooth' })
    
    const listing = await useListing(invoiceP.value, route.name)
    datas.value = listing.data.data.invoices.data
    total.value = listing.data.data.invoices.total
    invoiceP.value.page = listing.data.data.invoices.current_page
    invoiceP.value.per_page = listing.data.data.invoices.per_page
    footerData.value.from = listing.data.data.invoices.from
    footerData.value.to = listing.data.data.invoices.to
    footerData.value.total = listing.data.data.invoices.total
    loading.value = false
    loadOnce.value = false
    await count()
}

const dueCounts = computed(() => {
    if(Object.values(counts.value).length > 0) return { ...counts.value }

        return {
            all: 0,
            online_count: 0,
            cod_direct_count: 0,
            failed_payments_count: 0,
            cancelled_count: 0,
        }
})

const payments = ref([])
async function getPayments() {
    const reason = await useUpdateCustomerData({ linkType: 'payment-types' })
    
    let response = reason.data.data.types

    payments.value = response
}

const invoice_id = ref(0)
const invoiceData = ref()
const inst = getCurrentInstance()
async function action(value, id) {
    if(value == 'invoice_details') {
        const params = {
            online_invoice_id: id
        }
        await InvoiceService.getOnlinePaymentInfo(params)
        InvoiceService.setOnlinePaymentInfoModal(true)
        // const response = await useUpdateCustomerData(params)
        // invoiceData.value = response.data.data
    } else {
        invoice_id.value = id
        const item = inst.refs[value]
        setTimeout(() => {
            if(item != undefined) Array.isArray(item) ? item[0].open() : item.open()
        }, 500);
    }

    
}

function handleOption(data, opt) {
    invoice_ref.value.open()
    selectedRow.value = data
    selectedOpt.value = opt
}

function cancelOpt() {
    datas.value = datas.value.map(x => {
        let status = 1
        if(x.tab == 'discrepancy_count')
            status = 2

        return {
            ...x,
            status
        }
    })
}
const invoiceAlreadyActioned = ref(false)
const invoiceAlreadyActionedMessage = ref('')

function continueData() {
    invoiceAlreadyActioned.value = false
    invoiceAlreadyActionedMessage.value = ''
    invoice_ref.value.open(false)
}

async function SubmitOpt() {
    invoiceAlreadyActioned.value = false
    invoiceAlreadyActionedMessage.value = ''

    const data = {
        invoice_id: selectedRow.value.invoice_id,
        status: selectedOpt.value.id,
        item: selectedRow.value,
        linkType: 'update-invoice'
    }
    loadingModal.value = true

    const response = await useUpdateCustomerData(data)
    if(response.status == 200 && response.data.data.approved_invoice) {
        invoiceAlreadyActioned.value = true
        invoiceAlreadyActionedMessage.value = response.data.message
        invoice_ref.value.open()
    } else {
        await listing(invoiceP.value.page, false)
        invoice_ref.value.open(false)
        loadingModal.value = false
    }
    }

</script>
